import React,{ Fragment } from 'react';

const BreadCrumbs = props => (
		<div onClick={ () => { props.to === 'back' ? props.history.goBack() : props.history.push( props.to ) } } style={{ position:'relative', cursor:'pointer' }} className="banner-parent" >
			<div className="banner-child" >
				<img className="banner-img" alt="" src={ props.img } />
			</div>
			<div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'100%', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
			    <i style={{ position:'absolute', left:0, top:0, textShadow:'0 2px 4px rgba(0,0,0,0.5)', fontSize:'36px', padding:'20px', opacity:'0.75', color:'white' }} className="material-icons">arrow_backward</i>
    			<div style={{ position:'absolute', left:0, bottom:0, textAlign:'left', padding:'0 0 0 20px', opacity:'0.75', color:'white', fontSize:'12px', height:'60px', overflow:'hidden', width:'100%', fontWeight:'bold' }} >
    				<div style={{ display:'inline-block', padding:'10px 10px' }} >Home</div>
    				{ props.titles.map( ( a, i ) => (
    					<Fragment key={ i }>
		    				<div style={{ display:'inline-block', padding:'10px 0' }} >></div>
		    				<div style={{ display:'inline-block', padding:'10px 10px' }} >{ a }</div>
    					</Fragment>
					) ) }
    			</div>
			</div>
		</div>
)

export default BreadCrumbs