import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Store from '../Store/Store';
import Velocity from 'velocity-animate';
import Header from '../Header/Header';


const CreditCardShape = ( props ) => {
  const baseWidth = 80, baseHeight = 54, baseRadius = 4
  let ratio = Number( props.width || 120 ) / baseWidth
  let width = ( baseWidth * ratio ) + 'px'
  let height = ( baseHeight * ratio ) + 'px'
  let radius = ( baseRadius * ratio ) + 'px'
  return (
    <div style={{ position:'relative', display:'inline-block', cursor:'pointer', textAlign:'center', fontWeight:'500', margin:'0 10px', width:width, height:height, borderRadius:radius, color:props.color || "black", background:props.background, border:'1px solid black', padding:'10px', boxSizing:'border-box' }} >
      <div style={{ position:'absolute', left:'0', top:'50%', transform:'translateY(-50%)', padding:'10px' }} >
        <i className="material-icons" style={{ paddingRight:'5px', transform:'translateY(5px)' }} >{ props.checked ? 'radio_button_checked' : 'radio_button_unchecked' }</i>
        { props.title }
      </div>
    </div>
  )
}

const PageTitle = ( props ) => (
    <h2 style={{ width:'100%', fontWeight:'500', textAlign:'left', padding:'0 40px 0 0', margin:'0 0 30px 30px', boxSizing:'border-box' }} >{ props.page + '/' + props.numberOfPages } - { props.title }</h2>
)



const Page1 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Please choose your account plan" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ display:'inline-block', paddingLeft:'20px' }} >
        <CreditCardShape title="Investment" width="170" background="#C0C0C0" />
        <CreditCardShape title="Solo" width="170" background="#D4AF37" checked={ true } />
        <CreditCardShape title="Corporate" width="170" background="#000" color="white" />
      </div>
      <div style={{ padding:'10px 40px 0 30px' }} >
        <div onClick={ props.nextPage } className="open-account-button" >Continue</div>
        <Link to="/login/"><div style={{ fontSize:'16px', fontWeight:'500' }} >Already have a Fidelcrest account? <b>Log In</b></div></Link>
        <div style={{ fontSize:'12px', fontWeight:'500', marginTop:'20px' }} >By continuing you agree to our <b>Terms of Use</b>, <b>Privacy Policy</b> and <b>Fees</b></div>
      </div>
      <div style={{ height:'150px' }} />
    </div>
  </div>
)

const Page2 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Your account credentials" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ padding:'0 0 10px 30px' }} >
        <form style={{ marginRight:'50px' }} >
          <div>
            <label htmlFor="email" >Your email address</label>
            <input name="email" />
          </div>
          <div>
            <label htmlFor="password" >Create a password</label>
            <input name="password" type="password" />
          </div>
          <div onClick={ props.nextPage } className="open-account-button" >Continue</div>
          <div style={{ height:'230px' }} />

        </form>
      </div>
    </div>
  </div>
)

const Page3 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Your contact details" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ padding:'0 0 10px 30px' }} >
        <form style={{ marginRight:'50px' }} >
          <div>
            <label htmlFor="first_name" >First name</label>
            <input name="first_name" />
          </div>
          <div>
            <label htmlFor="last_name" >Last name (family name)</label>
            <input name="last_name" />
          </div>
          <div>
            <label htmlFor="phone" >Phone number</label>
            <input name="phone" />
          </div>
          <div>
            <label htmlFor="address" >Street address</label>
            <input name="address" />
          </div>
          <div>
            <label htmlFor="postal_code" >Postal code (zip)</label>
            <input name="postal_code" />
          </div>
          <div>
            <label htmlFor="city" >City</label>
            <input name="city" />
          </div>
          <div>
            <label htmlFor="country" >Country</label>
            <input name="country" />
          </div>
          <div onClick={ props.nextPage } className="open-account-button" >Continue</div>
          <div style={{ height:'20px' }} />

        </form>
      </div>
    </div>
  </div>
)

const Page4 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Your financial details" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ padding:'0 0 10px 30px' }} >
        <form style={{ marginRight:'50px' }} >
          <div>
            <label htmlFor="nationality" >Your nationality</label>
            <input name="nationality" />
          </div>
          <div>
            <label htmlFor="tax_domicile" >Your tax domicile</label>
            <input name="tax_domicile" />
          </div>
          <div>
            <label htmlFor="income" >Yearly income in Euros</label>
            <input name="income" />
          </div>
          <div>
            <label htmlFor="source_of_income" >Source of income</label>
            <input name="source_of_income" />
          </div>
          <div>
            <label htmlFor="net_wealth" >Net wealth in Euros</label>
            <input name="net_wealth" />
          </div>
          <div>
            <label htmlFor="investment_portfolio" >Investment portfolio in Euros</label>
            <input name="investment_portfolio" />
          </div>
          <div>
            <label htmlFor="investment_size" >Typical investment size in Euros</label>
            <input name="investment_size" />
          </div>
          <div onClick={ props.nextPage } className="open-account-button" >Continue</div>
          <div style={{ height:'20px' }} />

        </form>
      </div>
    </div>
  </div>
)

const Page5 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Documents" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ padding:'0 0 10px 30px' }} >
        <form style={{ marginRight:'50px' }} >
          <div>
            <label htmlFor="passport" >Copy of your passport (JPEG image or PDF)</label>
            <input name="passport" />
          </div>
          <div>
            <label htmlFor="tax_domicile" >Proof of address (e.g. an utility bill, PDF)</label>
            <input name="tax_domicile" />
          </div>
          <div onClick={ props.nextPage } className="open-account-button" >Continue</div>
          <div style={{ height:'20px' }} />

        </form>
      </div>
    </div>
  </div>
)

const Page6 = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Open Account</h1>
      <PageTitle title="Thank You!" page={ props.page } numberOfPages={ props.numberOfPages } />
      <div style={{ padding:'0 40px 10px 30px' }} >
        <p>Your account registration is complete. </p>
        <p>After reviewing your application, we will email you the confirmation and payment instructions.</p>
        <Link to="/"><div className="open-account-button" style={{ marginTop:'0', display:'inline-block' }} >Continue browsing</div></Link>
        <div style={{ height:'420px' }} />
      </div>
    </div>
  </div>
)

const Pages = ( props ) => {

  if ( props.page === 1 ) return <Page1 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />
  if ( props.page === 2 ) return <Page2 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />
  if ( props.page === 3 ) return <Page3 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />
  if ( props.page === 4 ) return <Page4 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />
  if ( props.page === 5 ) return <Page5 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />
  if ( props.page === 6 ) return <Page6 page={ props.page } numberOfPages={ props.numberOfPages } nextPage={ props.nextPage } />

}


// 86 × 54 

class OpenAccount extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      tmp:null,
      page:1,
      numberOfPages:6,
    };
    this.nextPage = this.nextPage.bind(this);
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0; 
    document.body.scrollTop = 0; // Safari
  }

  nextPage() {
    this.setState( { page:this.state.page + 1 } )
    document.documentElement.scrollTop = 0; 
    document.body.scrollTop = 0; // Safari
  }

  render() {
    return ( 
      <Fragment>
        <Pages page={ this.state.page } numberOfPages={ this.state.numberOfPages } nextPage={ this.nextPage } />
        <Header progressCount={ this.state.page } numberOfPages={ this.state.numberOfPages } />
      </Fragment>
    )
  }
}

export default OpenAccount
