import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import Data from '../Data/Data';
import BlogCard from '../Blogs/BlogCard';

class BlogsList extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
		};
	}

	render() {
		let limit = this.props.limit || 12
		return ( 
        	<div className="products" style={{ paddingTop:'0' }} >
		    	<div style={{ verticalAlign:'top', textAlign:'left', marginLeft:'20px', marginRight:'40px', boxSizing:'border-box', }} >

		    		{ Data.blogs.map( ( row, i ) => {
		    			if ( i >= Number( limit ) ) return <Fragment key={ row.slug  + '-' + i } />
	    				return (
    					<Fragment key={ row.slug  + '-' + i } >
			    		<Link 
 				    		draggable="false" 
				    		to={{
							  pathname:'/blogs/' + row.slug + '/',
							  state: {
							    backFromPageTo:this.props.backFromPageTo
							  }
							}} 
				    		className="blogs-list-block"
			    		>

			    		<BlogCard 
			    			row={ row }
			    		/>
			    		
				    	</Link>
    					</Fragment>

				    	)

			    	} ) }

			    </div>
			</div>
		)
	}
}

export default BlogsList
