import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; // must import Router, else switch doesn't work
//import Velocity from 'velocity-animate';
import Intro from '../App/Intro';
//import Activities from '../Activities/Activities';
//import ActivityPage from '../Activities/ActivityPage';
//import CategoryPage from '../Categories/CategoryPage';
import BlogPage from '../Blogs/BlogPage';
import Login from '../Login/Login';
import OpenAccount from '../OpenAccount/OpenAccount';
import Dashboard from '../Dashboard/Dashboard';
import Footer from '../App/Footer';
import '../css/index.css';

const App = ( props ) => {
  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Switch>
          <Route exact path="/" component={ Intro }/>
          <Route exact path="/blogs/:slug/" component={ BlogPage }/>
          <Route exact path="/login/" component={ Login }/>
          <Route exact path="/open-account/" component={ OpenAccount }/>
          <Route exact path="/dashboard/" component={ Dashboard }/>
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
      <Footer/>
    </Fragment>
  ) 
}

export default App
