import React from 'react';
//import Velocity from 'velocity-animate';
//import logoImage from '../img/fidelcrest-white.svg';
//const logoUrl = `${ logoImage }`

/*
const scrollToTop = () => {
    Velocity( document.documentElement, "scroll", { duration:1000, easing:"easeOutQuad" } )
}
*/

const Footer = () => (
  <div className="footer-wrapper" >
    <div className="footer" >
      <div className="footer-flex-box" >
        <div className="footer-item-group" >
          <small>Contact</small><br/>
          <b>Email:</b> jeraceramics@gmail.com<br/>
          <b>Instagram:</b> mettemceramics
        </div>

      {/*

        <div className="footer-item-group" >
          <small>Links</small><br/>
          <b>First<br/>
          Second<br/>
          Third<br/>
          Fourth</b>
        </div>
        <div className="footer-item-group" >
          <small>Information</small><br/>
          <b>First<br/>
          Second<br/>
          Third<br/>
          Fourth</b>
        </div>

        <div className="footer-item-group" >
          <div onClick={ () => { scrollToTop() } } style={{ textAlign:'left', fontSize:'14px', cursor:'pointer', fontWeight:'bold', color:'white' }} >
            <div style={{ display:'inline-block', textAlign:'center' }} >
              <i style={{ fontSize:'36px', padding:'0', cursor:'pointer', transform:'translateY(6px)' }} className="material-icons">keyboard_arrow_up</i>
            </div>
          </div>
        </div>
      */}

      </div>
    </div>
  </div>
)

export default Footer 
