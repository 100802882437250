import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import Data from '../Data/Data';
import DashboardCard from '../Dashboard/DashboardCard';

class DashboardList extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
		};
	}

	render() {
		let limit = this.props.limit || 12
		return ( 
        	<div className="products" style={{ paddingTop:'0', boxSizing:'border-box' }} >
		    	<div style={{ verticalAlign:'top', textAlign:'left', marginLeft:'0', marginRight:'40px', boxSizing:'border-box', }} >

		    		{ Data.dashboard.map( ( row, i ) => {
		    			if ( i >= Number( limit ) ) return <Fragment key={ row.title  + '-' + i } />
	    				return (
    					<Fragment key={ row.title  + '-' + i } >
			    		<DashboardCard 
			    			row={ row }
			    		/>
    					</Fragment>
				    	)

			    	} ) }

			    </div>
			</div>
		)
	}
}

export default DashboardList
