import React from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Velocity from 'velocity-animate';

//import logoImage from '../img/fidelcrest-black.svg';
//const logoUrl = `${ logoImage }`

const scrollTo = ( nodeId, duration = 500 ) => {
    let activities = document.getElementById( nodeId )
    Velocity( activities, "scroll", { duration:duration, easing:"easeOutQuad" } )
}

const NavScrollLink = ( props ) => (
    <div onClick={ () => { scrollTo( props.nodeId, props.duration ) } } style={{ display:'inline-block', padding:'0 10px', cursor:'pointer' }} >
      { props.title }
    </div>
)

const Nav = ( props ) => (
    <div className="front-nav-wrapper" >
	    <div className="front-nav" >

	    	{/*
			<div draggable="false"  style={{ position:'absolute', left:'20px', top:'0', height:'60px', fontWeight:'bold', fontSize:'18px', padding:'8px', boxSizing:'border-box' }} >
			<div draggable="false" className="nav-links" style={{ position:'absolute', fontSize:'16px', userSelect:'none', fontWeight:'500', left:'50%', transform:'translateX(-50%)', top:'7px', color:'#000', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px', boxSizing:'border-box' }} >
				<img draggable="false" style={{ height:'44px', boxSizing:'border-box' }} alt="Logo" src={ logoUrl }/>
			</div>
			*/}

			<div draggable="false" className="nav-links" style={{ position:'absolute', fontSize:'16px', userSelect:'none', fontWeight:'500', left:'20px', top:'7px', color:'#000', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px 0', boxSizing:'border-box' }} >
				<NavScrollLink title="Collection"  nodeId="account-plans"  duration="300" />
				<NavScrollLink title="Projects" nodeId="projects"       duration="400" />
				<NavScrollLink title="About"    nodeId="about"          duration="700" />
			</div>

	    	{/*
			<Link draggable="false"  to="/login/">
				<div style={{ position:'absolute', fontSize:'16px', fontWeight:'500', right:'190px', top:'7px', color:'#000', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px', boxSizing:'border-box' }} >
					Log In
				</div>
			</Link>
			*/}

	    	{/*
			<Link draggable="false"  to="/open-account/">
				<div style={{ position:'absolute', fontSize:'16px', fontWeight:'500', right:'20px', top:'7px', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px 20px', color:'white', background:'#26a69a', borderRadius:'5px', boxSizing:'border-box' }} >
					Open Account
				</div>
			</Link>
			*/}


	    </div>
    </div>
)

export default Nav
