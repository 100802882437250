import React from 'react';
import parse from 'html-react-parser';

const DashboardCard = props => (
	<table className="card-hover" style={{ width:'140px', cursor:'pointer', display:'inline-block', margin:'5px' }} >
		<tbody>
			<tr><td colSpan="2" style={{ width:'200px', textAlign:'center', height:'100px', maxHeight:'150px', padding:'10px', margin:'0', boxSizing:'border-box', background:'#ddd' }} >
				<i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>{ props.row.icon }</i>
				<div style={{ fontSize:'14px', lineHeight:'18px' }} >{ parse( props.row.title ) }</div>
			</td></tr>
			<tr><td colSpan="2" style={{ padding:'10px', verticalAlign:'top', boxSizing:'border-box' }} >
		    	<div style={{ padding:'0', margin:'0', fontSize:'14px', lineHeight:'20px', height:'60px', overflow:'hidden', boxSizing:'border-box' }} >{ parse( props.row.content ) }</div>
			</td></tr>
		</tbody>
	</table>
)

export default DashboardCard
