import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work
import Store from '../Store/Store';
import Velocity from 'velocity-animate';
import Header from '../Header/Header';

const LoginForm = ( props ) => (
  <div id="login-form" style={{ marginTop:'0' }} >
    <div className="products" style={{ textAlign:'left' }}  >
      <h1 style={{ padding:'50px 0 30px 30px', boxSizing:'border-box', marginBottom:'0' }}>Log In</h1>
      <div style={{ padding:'0 0 10px 30px' }} >
        <form style={{ marginRight:'50px' }} >
          <div>
            <label htmlFor="email" >Email</label>
            <input name="email" />
          </div>
          <div>
            <label htmlFor="password" >Password</label>
            <input name="password" type="password" />
          </div>
          <div style={{ fontSize:'16px', fontWeight:'500', marginTop:'20px' }} ><b>Forgot password?</b></div>
          <Link to="/dashboard/"><div className="open-account-button" >Log In</div></Link>
          <Link to="/open-account/"><div style={{ fontSize:'16px', fontWeight:'500' }} >New to Fidelcrest? <b>Open account</b></div></Link>
          <div style={{ fontSize:'12px', fontWeight:'500', marginTop:'20px' }} >By continuing you accept our <b>Terms of Use</b></div>
          <div style={{ height:'120px' }} />

        </form>
      </div>
    </div>
  </div>
)


class Login extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      tmp:null,
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0; 
    document.body.scrollTop = 0; // Safari
  }

  updateInputValue( e, stateObject, type ) {
    let a = this.state[ stateObject ]
    a[ type ] = e.target.value
      this.setState( { [ stateObject ]:a } )
      //console.log( 'this.state.answers', this.state[ stateObject ] )
  }

  render() {
    return ( 
      <Fragment>
        <LoginForm/>
        <Header/>
      </Fragment>
    )
  }
}

export default Login
