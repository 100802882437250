
const Data = {

	productLists: [
		{ title:'Wellness', 
		  productListId:'7838', 
		  img:'Wellness/Inkoo_8M0A0691.jpg', 
		  copy:`<h2>Finns heals their body and soul with nature and sauna</h2>
				<p>Finnish people are known to be calm and composed even in stressful situations. Walk in the forest, sauna and dip in the lake are known to release anxiety and Finns are taking full advantage of these remedies that heals body and soul. Maybe you want to dedicate your holiday in Finland to relaxation and wellness. Natural place to start with is of course sauna. Sauna lowers blood pressure, relaxes muscles and increases blood flow and endorphins in your body. The after sauna feeling is amazing. Finns have a special term for that which is called “after sauna glow”.</p>
				<h2>Helsinki has a sauna for every taste</h2>
				<p>To get your holiday mood on right away you should try one of the numerous public saunas in Helsinki. The range is wide. You can have a bath in a very old school sauna or have a luxurious sauna experience with different types of saunas, pools and activities. One of the most memorable and exclusive experience is Allas Sea Pool located right in the center of the city. At Allas Sea Pool, you can have a swim next to the President’s castle and enjoy the sea view while having a sauna. </p>` 
		},
		{ title:'Design', 
		  productListId:'7856', 
		  img:'Design/Aalto-4.jpg', 
		  copy:`<h2>Finland has long and successful design heritage</h2>
			<p>Maybe you have heard of Marimekko textiles, Iittala Arabia tableware, Iittala glassware, Artek furniture or Minna Parikka´s bunny sneakers, that celebrities all over the world are wearing. Finland for sure have a lot of skillful designers for a small country. Design is everywhere and it is hard to avoid to see iconic finnish design products even on a short weekend trip. </p>
			<h2>Helsinki is the design capital of Finland</h2>
			<p>Helsinki is well known for its chic designer boutiques and art galleries. Get to know Finnish design and purchase some top-class Finnish and Scandinavian design products to take home while exploring the Design District in Punavuori. You also have the option to purchase souvenirs online from Finnish Design Shop or Ristomatti Ratia´s online shop and get it delivered home. </p>` 
		},
		{ title:'Culture', 
		  productListId:'7848', 
		  img:'Culture/Finnish_National_Opera_Katsomo 11_Heikki_Tuuli.jpg', 
		  copy:`<h2>Abundance of cultural venues can be found in Finland</h2>
			<p>Finland is rich with its historical landmarks, cultural activities and events taking place all year around. Whether you have the visit planned in summer, winter or even when the seasons are changing, there are always new and exciting venues to experience.</p>
			<p>If you are traveling to Finland during summer time, make sure to take part in Sibelius Finland Experience or visit one of the numerous museums located in beautiful Finnish Lakeland. </p>
			<p>If you want to focus on understanding what Finns are all about on your layover, we have a wide selection of memorable experiences where local people guide you to discover Finland in a unique way and find interesting things that Finns do, which only a local would know.</p>
			<h2>Best way to explore culture in Helsinki</h2>
			<p>Helsinki being the capital city offers numerous things to do when it comes to museums, exhibitions, concerts or art. If you are having a city break in Helsinki, you are in for a treat. To get the most of your holiday in Helsinki, we recommend to purchase Helsinki Card that will give you access to most of the museums and sights in addition to free use of public transportation. On a short holiday or weekend away you can just pick one main venue from our selection and walk around the beautiful city of Helsinki.</p>` 
		},
		{ title:'Adventure', 
		  productListId:'5304', 
		  img:'Adventure/Finland_Helsinki_Rhododendron_park_julia kivela.jpg', 
		  copy:`<h2>Finland offers great experiences to those who dare to jump in</h2>
			<p>Four seasons and variation in geographic in Finland offers visitors many exciting adventures, that you can only experience in a Nordic country. When you are spending your holiday in Finland make sure to take some thrill back with you by taking part on one or two of these exquisite tours. </p>
			<p>Arctic regions in the winter time transform into the picturesque winter wonderland you have never seen before. Our tailored packages makes sure you are having a time of your life and takes you to an adventure from swimming in the icy lake to having racy sledge ride with huskies and meeting Santa Clauses reindeers. You will get your adrenaline levels up in high gear, after ice climbing or high speed boat cruise which you certainly will remember.</p>
			<h2>Helsinki has many adventures to spice up your city break</h2>
			<p>To experience adventure on your stopover in Helsinki is a must. Given how accessible everything is, Helsinki coast and archipelago offers loads to explore and experience. Even though you are in an urban area, nature is never too far away in Finland. Within less than an hour drive you’ll find yourself trekking in the forest or going for a horseback ride.</p>` 
		},
		{ title:'Helsinki', 
		  productListId:'8056', 
		  img:'Helsinki/HKI_8M0A4955.jpg', 
		  copy:`<h2>Finland's capital city offers vast variety of things to do</h2>
			<p>Helsinki is the perfect city for weekend get away. It is small enough to be able to grasp an idea of it on a short stopover but big enough to offer vast variety things to do and see. For a food lover there are plenty of exciting restaurants to choose from. For example, iconic restaurant Savoy located next to the beautiful Esplanad park is one of the oldest and finest restaurants in town. If you are looking for art and culture make sure you stop by in modern art museums Kiasma and Amos Rex. They are both unique in architecture and host exhibitions from artists around the world. Helsinki Central Library Oodi is somewhat an extension of living room for Helsinki habitants. This library is magnificent from inside and out. Interesting architecture and views to the Parliament house and central Helsinki makes Oodi an unique place well worth visiting.</p>
			<p>Whether you want to relax, explore urban culture, do shopping or have a sauna and dip in the sea, it's all possible in Helsinki. For shopping local products, Design District Helsinki is great place to get a glimpse to Finnish design. Hip and boheem area Kallio has interesting clubs and restaurants for those who want to dive a bit deeper into Helsinki scene. </p>` 
		},
		{ title:'Lapland', 
		  productListId:'8058', 
		  img:'Lapland/IMG_0248.jpg', 
		  copy:`<h2>Lapland is full of magic all year around</h2>
			<p>Lapland is definitely one of the most exciting and extraordinary regions in Europe. Everyone knows that Lapland is where Santa Claus is from. Santa is only one of the fairytale like attractions in Lapland. In the winter time you can spot the most amazing Aurora Borealis lighting up the sky with luminous, constantly moving colors. The capital of Lapland, Rovaniemi is the only city in the world, where you can cross Arctic Circle. Above the Arctic Circle the sun does not set at all at mid-summer  and vise versa, never rises in the heart  winter. The spring time in Lapland is perfect for skiing with white powdery slopes and lots of sun. In the autumn the fall colours are breathtaking. Fall time is perfect for hikes in the forest and enjoy the views.</p>
			<p>Lapland is unique place on earth where you can experience purest air and water spiced up with amazing wild nature and a ton of magic.</p>` 
		},
		{ title:'Archipelago', 
		  productListId:'8057', 
		  img:'Archipelago/MG_9806_Porvoo_(c)_Jussi_Hellsten.jpg', 
		  copy:`<h2>Finnish archipelago is among the largest in world</h2>
			<p>Situated between Gulf of Finland and Gulf of Botnia Finnish archipelago consists over 40,000 islands and islets. Between islands and mainland water busses are taking care of the transportation. Summertime island hopping by hiking or with bicycle is an experience to remember. There are many well maintained routes and lots of camping areas and also of course hotels to choose from and other accommodation options. For example on the island of Bengtskär you can spend your night in the light house. These magical islands have all charm of their own and it is hard to pick a favourite one. Whether you have short holiday in Finland or longer time to explore, Finnish archipelago is something you should experience. </p>` 
		},
		{ title:'Lakeland', 
		  productListId:'8059', 
		  img:'Lakeland/Sami_Tuoriniemi_Nummela_Hiidenvesi_DJI_0139.jpg', 
		  copy:`<h2>Finland has the largest lake district in Europe</h2>
			<p>Lakeland is in the core of Finnish identity. Thousand of lakes cover big part of the central Finland. These pure watered, glittering havens are surrounded by green forests, fields and hills. Together they create the very essence of Finland. Lakeland is perfect for enjoying nature and summer days in the cottage. These thousands lakes also offers great scenery to explore by sailing, by paddling or by an in-land cruise. Lakeland is definitely one of the Finland’s most popular districts to enjoy beautiful nature and just relax. Many activities are taking place in the area especially in the summertime. For example Savonlinna Opera Festival, held in the medieval castle is the highlight of the summer for people around the country and definitely exclusive experience. </p>` 
		},
		/*
		{ title:'Sightseeing', 
		  productListId:'8055', 
		  img:'Sightseeing/Finland_Helsinki_people_highres_byJuliaKivela__MG_4267.jpg', 
		  copy:`<h2>Explore new places without a hassle</h2>
			<p>The best way to explore city when you are on weekend getaway is definitely sightseeing. When you only have few days or hours to spend on your stopover, let these carefully selected tours to show you the Finland's best features. </p>
			<h2>Take over the city of Helsinki by bus tour or a guided walk</h2>
			<p>When you want to get an idea of the city and learn about its history and places, there are no better way to get started than good old sightseeing bus tour. Especially when you don't have too much time to spare on your weekend getaway, sitting comfortably in the bus is a great and hassle free way to see a lot. If you want to have more down to earth experience, you can choose a walking tour and get to know the nearby sights and attractions while blending in the streets of Helsinki. </p>` 
		},
		{ title:'Culinary', 
		  productListId:'7830', 
		  img:'Culinary/IMG_0784_Yiping Feng and Ling Ouyang.jpg', 
		  copy:`<h2>Nordic culinary is exclusive and relies on natural ingredients (otsikko muuttuu, teksti draft)</h2>
			<p>For some people Finnish food may seem bland and boring at first sight. It is true that Finnish people are not used to strong spices and the harsh nature has somewhat limited the natural selection of plants, game and seafood. </p>
			<p>Finns have got used to live close to the nature and changing seasons. That has shaped us to be experts on making the most of scarce resources.</p>
			<p>To start with your Finnish adventure, if you have never tasted what reindeer or bear meat, then it is a must have culinary experience on your visit to Finland.</p>
			<p>Finnish nature is full of pure and tasty, plants and berries locally considered as super food. Berries from Finnish forests are one of the most flavor filled and offer great nutrition. An endeared part of Finnish culture.</p>
			<h2>Most authentic Finnish culinary experiences includes sauna and nature</h2>
			<p>In Finland we like to combine the things we most enjoy. Proper sauna experience is only fulfilled with a hardy dinner. It is also commonly known fact that everything tastes better outdoors. To experience Finnish culture and culinary we strongly recommend you to try out dining in the nature and after sauna. </p>` 
		},
		*/
	],

	blogs: [
		{ title:'Fidelcrest to build luxury hotel in Gudauri, Georgia', 
		  slug:'fidelcrest-to-build-luxury-hotel-in-gudauri-georgia',
		  date:'May 19th, 2019',
		  productListId:'7848', 
		  img:'blogs/vega5270_1-1500x800-1200x640.jpg', 
		  copy:`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nisl ut augue iaculis semper at sed ligula. Fusce leo tellus, placerat et pretium non, sodales non ipsum. Aliquam auctor dui est, ut ornare urna tincidunt nec. Pellentesque eget ligula vitae ex rhoncus accumsan. Ut nec sem felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis tempus blandit tortor. Aliquam dictum risus felis, et volutpat nulla semper in. </p>
          <p>Sed vehicula orci quis porta vulputate. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras quis nulla ullamcorper, congue massa sed, sodales nisi. Maecenas eu scelerisque dolor. Fusce tempor efficitur nunc, ut ullamcorper orci mollis nec. Quisque condimentum diam a finibus tristique. Curabitur vitae metus sollicitudin, aliquet dolor eu, accumsan nisl. Vestibulum sit amet aliquam felis, et lobortis mauris. Suspendisse potenti. </p>`
		},
		{ title:'Automatic Exchange of Information – Russia is no longer reportable', 
		  slug:'automatic-exchange-of-information–russia-is-no-longer-reportable',
		  date:'May 3rd, 2019',
		  productListId:'5304', 
		  img:'blogs/World-Information-Exchange.jpg', 
		  copy:`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nisl ut augue iaculis semper at sed ligula. Fusce leo tellus, placerat et pretium non, sodales non ipsum. Aliquam auctor dui est, ut ornare urna tincidunt nec. Pellentesque eget ligula vitae ex rhoncus accumsan. Ut nec sem felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis tempus blandit tortor. Aliquam dictum risus felis, et volutpat nulla semper in. </p>
          <p>Sed vehicula orci quis porta vulputate. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras quis nulla ullamcorper, congue massa sed, sodales nisi. Maecenas eu scelerisque dolor. Fusce tempor efficitur nunc, ut ullamcorper orci mollis nec. Quisque condimentum diam a finibus tristique. Curabitur vitae metus sollicitudin, aliquet dolor eu, accumsan nisl. Vestibulum sit amet aliquam felis, et lobortis mauris. Suspendisse potenti. </p>`
		},
		{ title:'Fidelcrest Forex Challenge launched to recruit skilled traders', 
		  slug:'fidelcrest-forex-challenge-launched-to-recruit-skilled-traders',
		  date:'February 3rd, 2019',
		  productListId:'5304', 
		  img:'blogs/10933698_10207929545208980_5469016385615640349_n.jpg', 
		  copy:`<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet nisl ut augue iaculis semper at sed ligula. Fusce leo tellus, placerat et pretium non, sodales non ipsum. Aliquam auctor dui est, ut ornare urna tincidunt nec. Pellentesque eget ligula vitae ex rhoncus accumsan. Ut nec sem felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Duis tempus blandit tortor. Aliquam dictum risus felis, et volutpat nulla semper in. </p>
          <p>Sed vehicula orci quis porta vulputate. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras quis nulla ullamcorper, congue massa sed, sodales nisi. Maecenas eu scelerisque dolor. Fusce tempor efficitur nunc, ut ullamcorper orci mollis nec. Quisque condimentum diam a finibus tristique. Curabitur vitae metus sollicitudin, aliquet dolor eu, accumsan nisl. Vestibulum sit amet aliquam felis, et lobortis mauris. Suspendisse potenti. </p>`
		},
	],

	dashboard: [
		{ title:'Incoming<br>Invoices', 
		  icon:'description', 
		  content:`<small><b>Total</b></small> 78000 <small>€</small><br>
          <small><b>Open</b></small> 18000 <small>€</small>`
		},
		{ title:'Outgoing<br>Payments', 
		  icon:'credit_card', 
		  content:`<small><b>Total</b></small> 34000 <small>€</small><br>
          <small><b>Pending</b></small> 8000 <small>€</small>`
		},
		{ title:'Investments', 
		  icon:'work_outline', 
		  content:`<small><b>Total</b></small> 16000 <small>€</small><br>
          <small><b>Profit</b></small> 800 <small>€</small>`
		},
		{ title:'Loans', 
		  icon:'hourglass_empty', 
		  content:`<small><b>Capital</b></small> 20000 <small>€</small><br>
          <small><b>Accrued</b></small> 485 <small>€</small>`
		},
		{ title:'Statements', 
		  icon:'euro_symbol', 
		  content:`<small><b>Total</b></small> 37000 <small>€</small><br>
          <small><b>Available</b></small> 8000 <small>€</small>`
		},
		{ title:'Messages', 
		  icon:'email', 
		  content:`<small><b>New</b></small> 3<br>
          <small><b>Total</b></small> 104`
		},
	],


}

export default Data

/*
		{ title:'', 
		  slug:''
		  img:'finland-2548616_1920.jpg', 
		  copy: `` 
		},
*/