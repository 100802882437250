import React from 'react';
import { Link } from 'react-router-dom'; // must import Router, else switch doesn't work

//import logoImage from '../img/fidelcrest-black.svg';
//const logoUrl = `${ logoImage }`

const Progress = ( props ) => {
	if ( !props.numberOfPages ) return null
	let c = Array( Number( props.numberOfPages ) ).fill( 0 )
	return c.map( ( a, i ) => {  
		return <div key={ i } style={{ display:'inline-block', width:'60px', height:'4px', background: i < props.progressCount ? '#80cbc4' : '#ddd', margin:'0 3px' }} />
	} )
}

const Header = ( props ) => (
    <div className="front-nav-wrapper" >
	    <div className="front-nav" >


	    	{/*
			<Link to="/">
				<div style={{ position:'absolute', left:'20px', top:'0', height:'60px', fontWeight:'bold', fontSize:'18px', padding:'8px', boxSizing:'border-box' }} >
					<img draggable="false" style={{ height:'44px', boxSizing:'border-box' }} alt="Logo" src={ logoUrl }/>
				</div>
			</Link>
			*/}
			<div className="nav-links" style={{ position:'absolute', fontSize:'16px', fontWeight:'500', left:'50%', transform:'translateX(-50%)', top:'7px', color:'#000', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px', boxSizing:'border-box' }} >
				<Progress numberOfPages={ props.numberOfPages } progressCount={ props.progressCount } />
			</div>

	    	{/*
			<Link to="/">
				<div style={{ position:'absolute', fontSize:'16px', fontWeight:'500', right:'20px', top:'5.5px', color:'#000', verticalAlign:'top', fontFamily:'Montserrat', padding:'10px', boxSizing:'border-box' }} >
					<i className="material-icons" style={{ transform:'translate(-2px, 6.4px)' }}>arrow_back</i>
					Back
				</div>
			</Link>
			*/}
	    </div>
    </div>
)

export default Header